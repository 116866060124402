import React, { useEffect, useState } from 'react';
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import {SGP} from "./sgp-results-react";

const Autoload = ({lastUpdated = new Date()}) => {

    return (
        <div style={{display:'flex',alignItems:'baseline'}}>
            <div style={{width: '10px',height: '10px',display:'inline-block',background:'#00C500',borderRadius: '5px',marginRight: '6px'}}></div>
            <Typography variant={'body2'}>LIVE {moment(lastUpdated).format('HH:mm:ss')}</Typography>
        </div>
    );
};

export default Autoload;