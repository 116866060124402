import React, {useEffect} from 'react';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import PilotRegistrationList from "./PilotRegistrationList";
import {getChampionshipTitle, getEventDatesString} from "../util/sgp-util";
import {SGP} from "./sgp-results-react";
import {eventStatus} from "../util/standards-util";
import PilotEntryList from "./PilotEntryList";

const Pilots = ({editionId}) => {

    const [event, setEvent] = React.useState()

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {
            const evt = await (
                await fetch(SGP.dataUrl+`/public/get/event?id=${editionId}`)
            ).json();

            setEvent(evt)
        };

        dataFetch();
    }, []);

    return (
        <Container maxWidth={'lg'}>
            <Box sx={{
                display:'flex',
                alignItems:'center',
                //justifyContent:'space-between',
                my:'1em',
                columnGap:'2em',
            }}>
                <Typography variant={'h4'} flexGrow={1} textAlign={'left'}>Pilots</Typography>
            </Box>
            {event &&
                <>
                    <Box sx={{textAlign:'left',bgcolor:'#333333',color:'#ffffff'}} my={3} p={2}>
                        <Stack direction={'row'}>
                            <Box mr={'24px'}>
                                <img src='https://sgp.crosscountry.aero/etc/img/sgp-series-logo-v3.png' alt="SGP" style={{width:'160px'}}/>
                            </Box>
                            <Box>
                                <Typography variant={'h5'}>{getChampionshipTitle(event)}</Typography>
                                <Typography variant={'h5'}>{event.fullEditionTitle}</Typography>
                                <Typography variant={'h6'}>{event.venue}</Typography>
                                <Typography variant={'h6'}>{getEventDatesString(event)}</Typography>
                            </Box>
                        </Stack>
                    </Box>
                    {event && event.status >= eventStatus.STATUS_PLANNED_REGISTRATION_CLOSED &&
                        <PilotRegistrationList event={event}/>
                    }
                    {event && event.status < eventStatus.STATUS_PLANNED_REGISTRATION_CLOSED &&
                        <PilotEntryList event={event}/>
                    }
                </>
            }
        </Container>
    )
}

export default Pilots