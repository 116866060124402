import React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {SGP} from "./sgp-results-react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Stack} from "@mui/material";

const CompetitionHeader = ({comp, dayIndex, autoload}) => {
    let title1, title2

    if (dayIndex > -1 && dayIndex < comp.i.length) {
        const  day = comp.i[dayIndex]
        title1=<Typography variant={'h6'}>{day.t}</Typography>
        title2=<Typography variant={'body1'}>{day.y===4 || day.y===3 ? "" : day.r && day.r.u===2 ? "Official race classification" : day.r && day.r.u===1 ? "Unofficial race classification" : "Preliminary race classification"}</Typography>
    }
    else if (dayIndex === comp.i.length) {
        title1=<Typography variant={'h6'}>Event classification</Typography>
        title2=<Typography variant={'body1'}>{comp.i[comp.i.length-1].y===3 ? "Official" : comp.i[comp.i.length-1].y===2 ? "Unofficial" : comp.j===comp.i.length-1 && comp.i[comp.j].r.u===2 ? "Official" : comp.j===comp.i.length-1 && comp.i[comp.j].r.u===1 ? "Unofficial" : "Preliminary"}</Typography>
    }
    else if (dayIndex === -1) {
        title1=<Typography variant={'h6'}>Event schedule</Typography>
    }


    return (
        <Box sx={{textAlign:'left',bgcolor:'#333333',color:'#ffffff'}} my={2} p={2}>
            <Grid2 container>
                <Grid2 xs={12} md={9}>
                    <Stack direction={'row'}>
                        <Box mr={'24px'}>
                            <img src='https://sgp.crosscountry.aero/etc/img/sgp-series-logo-v3.png' alt="SGP" style={{width:'160px'}}/>
                        </Box>
                        <Box>
                            <Typography variant={'h5'}>{SGP.getChampionshipTitle(comp)}</Typography>
                            <Typography variant={'h5'}>{comp.c.t}</Typography>
                            <Box my={1}>
                                {title1}
                                {title2}
                            </Box>
                        </Box>
                    </Stack>
                </Grid2>
                <Grid2 xs={12} md={3} sx={{display:'flex',flexDirection:'column',alignItems:'end'}}>
                    <Box><img style={{width:'164px'}} src={'https://sgp.crosscountry.aero/etc/img/sgp-partner-white.png'} alt='Crosscountry - Official SGP Scoring Partner'/></Box>
                    <Box sx={{paddingRight:'5px',display:'flex',flexDirection:'column',justifyContent:'flex-end'}} flexGrow={1} justifyContent={'flex-end'}>{autoload}</Box>
                </Grid2>
            </Grid2>
        </Box>
    )
};

export default CompetitionHeader;