import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from "./theme";
import reportWebVitals from './reportWebVitals';
import App from "./App";

const editionId = document.getElementById('sgp-results-root')?.getAttribute('data-event-id')
const noEmbed = document.getElementById('sgp-results-root')?.getAttribute('data-no-embed')
const mode = document.getElementById('sgp-results-root')?.getAttribute('data-mode')
const root = ReactDOM.createRoot(document.getElementById('sgp-results-root'));
root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App editionId={editionId} noEmbed={noEmbed} mode={mode}/>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
