import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const pages = [
    {name:'Home',path:'home'},
    {name:'Results',path:'results'},
    {name:'SGP events',path:'events'},
    {name:'Main site',path:'main'},
];


function MenuBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" sx={{backgroundColor:'#214568'}}>
            <Container maxWidth="xl">
                <Toolbar variant={'dense'} sx={{minHeight:'46px',height:'46px'}} disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.path}  onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center"><a style={{textDecoration:'none',color:'inherit'}}  href={`/${page.path}`}>{page.name}</a></Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <a href={'/'}>
                        <Box
                            sx={{
                                mr: 5,
                                display: { xs: 'flex' },
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src='/etc/img/cc-logo-v3-white.png' style={{height:'24px'}}/>
                        </Box>
                    </a>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.path}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, display: 'block', textTransform:'none' }}
                                color={'inherit'}
                            >
                                <a style={{textDecoration:'none',color:'white'}}  href={`/${page.path}`}>{page.name}</a>
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default MenuBar;