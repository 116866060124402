import React from 'react';
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment-timezone";
import Map from "../Components/Map"
import MapControls from "../Components/Map/MapControls";
import DayTaskRenderer from "./DayTaskRenderer";

const ViewPane = ({day,open, setOpen}) => {

    const handleClose = () => {
        setOpen(false)
    }

    const taskDistance = JSON.parse(day.k.description).d;
    const taskStartTime = moment.utc(day.a).format('HH:mm')

    let desc="";
    let pts=day.k.data.g;
    for (let i=0; i<pts.length;i++) {
        desc+=(i>0 ? " - " : "")+pts[i].n;
    }

    return (
        <Dialog open={open}
                fullWidth={true}
                maxWidth={'lg'}
                onClose={handleClose}>
            <DialogTitle>{day.t}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box sx={{bgcolor:'#f0f0f0',textAlign:'left'}} p={1}>
                    <Typography variant={'body2'}><span style={{fontWeight:700}}>Start time:</span> {taskStartTime}</Typography>
                    <Typography variant={'body2'}><span style={{fontWeight:700}}>Task:</span> {taskDistance}</Typography>
                    <Typography variant={'body2'} sx={{fontStyle:'italic',fontSize:'12px',mt:'4px'}}>{desc}</Typography>
                </Box>
                <Box>
                    <Map lat={day.k ? day.k.data.at.a : 0} lon={day.k ? day.k.data.at.o : 0} zoom={14} height={'640px'}>
                        <MapControls/>
                        { day && day.k && <DayTaskRenderer day={day}/> }
                    </Map>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ViewPane;