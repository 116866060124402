import moment from "moment-timezone";
import countries from "i18n-iso-countries";
import enLocale from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(enLocale)

export const countryObj = countries.getNames("en", { select: "official" });
export const countryArray = Object.entries(countryObj).map(([key, value]) => {
    return {label:value, key:key}
});

export const getChampionshipTitle = (comp) => {
    return `FAI Sailplane Grand Prix - Series ${romanize(comp.c ? comp.c.s : comp.series)}`
}

export const romanize = (num) => {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
            "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
            "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export const getEventDatesString = (comp)=> {
    const firstDate = comp.firstRacingDate ? comp.firstRacingDate : comp.firstDate
    if (moment(firstDate).year() === moment(comp.lastDate).year()) {
        if (moment(firstDate).month() === moment(comp.lastDate).month()) {
            return `${moment(firstDate).format('D')} - ${moment(comp.lastDate).format('D MMMM yyyy')}`
        }
        else {
            return `${moment(firstDate).format('D MMMM')} - ${moment(comp.lastDate).format('D MMMM yyyy')}`
        }
    }
    else {
        return `${moment(firstDate).format('D MMMM yyyy')} - ${moment(comp.lastDate).format('D MMMM yyyy')}`
    }
}

