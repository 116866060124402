import React, {useContext} from 'react';
import {useMap} from 'react-leaflet'
import L from "leaflet";
import {CCM} from "./sgp-results-react"

const DayTaskRenderer = ({day}) => {
    const map = useMap()

    const heightFt=day.r.m ? true : false;

    const ccmap=map.ccmap;

    if (!map || !ccmap) return

    if (ccmap.taskLayer) {
        ccmap.taskLayer.clearLayers();
    }
    else {
        ccmap.taskLayer = new L.FeatureGroup()
        ccmap.taskLayer.addTo(map)
    }

    CCM.task.draw(day.k,ccmap.taskLayer);
    map.fitBounds(ccmap.taskLayer.getBounds());


    /*
    var detailsDiv=$id('taskDescriptionTarget');
    detailsDiv.innerHTML='';

    var wpts=day.k.data.g;

    var boxes=d3.select(detailsDiv).selectAll("div")
        .data(wpts)
        .enter().append("div")
        .attr("class","wptDesc");

    var l1=boxes.append("div")
        .attr("class","l1");

    l1.append("div")
        .attr("class","wptNo")
        .html(function(w,idx) {return idx==0 ? 'Start' : idx==wpts.length-1 ? 'Finish' : 'TP '+idx});

    l1.append("div")
        .attr("class","wptName")
        .html(function(w) {return w.n});

    var l2=boxes.append("div")
        .attr("class","l1");

    l2.append("div")
        .attr("class","wptStyle")
        .html(function(w,idx) {return idx==0 ? 'Line: length '+(w.r ? (w.r/1000*2) : '5')+' km' : idx==wpts.length-1 ? 'Line: length '+(w.r ? (w.r/1000*2) : '0.5')+' km' : 'Cylinder'+( w.r && w.r!=500 ? ' r='+(w.r/1000)+' km': '')})

    l2.append("div")
        .attr("class","wptAlt")
        .html(function(w,idx) {return idx==0 ? (day.h ? "Max alt: "+(heightFt ? day.r.m.h+" ft" : day.h+" m") : "&nbsp;") : idx==wpts.length-1 ? (day.f ? "Min alt: "+(heightFt ? day.r.m.f+' ft' : day.f+" m") : "&nbsp;") : "&nbsp;"});

    l2.append("div")
        .attr("class","wptDistance")
        .html(function(w,idx) {return idx==0 ? "&nbsp;" : Math.round(L.latLng(wpts[idx-1].a,wpts[idx-1].o).distanceTo([w.a, w.o])/1000)+' km'});

    l2.append("div")
        .attr("class","wptBearing")
        .html(function(w,idx) {return idx==0 ? "&nbsp;" : Math.round(CCM.task.calcBearing(L.latLng(wpts[idx-1].a,wpts[idx-1].o), L.latLng(w.a, w.o)))+"&deg;"});

*/





}

export default DayTaskRenderer