import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import {ArrowBack} from "@mui/icons-material";
import {sortEntriesPerSgpRules, StyledTableCell, StyledTableRow} from "./PilotRegistrationList";
import {
    countryArray, registrationStatus
} from "../util/standards-util";
import {Alert, Dialog, DialogContent} from "@mui/material";
import {findFlagUrlByIso2Code} from 'country-flags-svg';
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {SGP} from "./sgp-results-react";


const PilotEntryList = ({event}) => {

    const eventId = event.id
    const [mode, setMode] = React.useState('list')
    const [entries, setEntries] = React.useState()
    const [pilotRlId, setPilotRlId] = React.useState()

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {
            const data = await (
                await fetch(SGP.dataUrl+`/public/get/event/registrations?eventId=${eventId}`)
            ).json();

            const entries=data.registrations.map((reg)=>{
                return {
                    registration:reg,
                    pilot:data.pilots.find(p => p.id === reg.p),
                }
            }).filter((entry)=>{
                return entry.registration.u === registrationStatus.STATUS_APPROVED
            }).sort((a,b) => {
                return a.registration.e.localeCompare(b.registration.e)
            })

            setEntries(entries)
        };

        dataFetch();
    }, []);

    const handlePilotClicked = (e,id) => {
        e.stopPropagation()
        console.log(`rl ${id}`)
        setPilotRlId(id)
        setMode('rlPilotInfo')
    }


    const onCloseRlPilotInfo = () => {
        setMode('list')
        setPilotRlId()
    }


    return (
        <>
            {entries && mode === 'list' &&
                <TableContainer component={'div'} sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{textAlign:'center'}}>ID</StyledTableCell>
                                <StyledTableCell>Pilot</StyledTableCell>
                                <StyledTableCell sx={{textAlign:'center'}}>Nationality</StyledTableCell>
                                <StyledTableCell>Sailplane</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entries.map((entry,idx) => {
                                    const reg= entry.registration
                                    const pilot = entry.pilot
                                    return (
                                        <StyledTableRow
                                            key={reg.i}
                                            sx={{
                                                '&:last-child td, &:last-child th': {border: 0},
                                                "&:hover": {backgroundColor: "#D3EDF4 !important"},
                                            }}
                                        >
                                            <StyledTableCell component="th" scope="row"  sx={{textAlign:'center'}}>
                                                <div style={{display:'inline-block',background:'#ffffff',padding:'1px 3px',marginTop:'-2px',marginBottom:'-2px',borderRadius:'4px',verticalAlign:'text-bottom',fontSize:'10pt',border:'1px solid #999999'}}>{reg.e}</div>
                                            </StyledTableCell>
                                            <StyledTableCell><Link underline={"none"} onClick={(e) => {handlePilotClicked(e,reg.d)}} sx={{cursor:'pointer'}}>{pilot.firstname} {pilot.lastname}</Link></StyledTableCell>
                                            <StyledTableCell sx={{textAlign:'center'}}><Tooltip title={countryArray.find( c => c.key===pilot.nationality).label} arrow><img src={findFlagUrlByIso2Code(pilot.nationality)} style={{height:'1.2em'}}/></Tooltip></StyledTableCell>
                                            <StyledTableCell>{reg.f}</StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {entries && mode === 'list' && entries.length === 0 &&
                <Alert severity={'info'} sx={{textAlign:'center'}}>There are no pilots registered for this event</Alert>
            }
            { mode === 'rlPilotInfo' &&
                <Dialog onClose={onCloseRlPilotInfo} open={true} fullWidth maxWidth="lg">
                    <DialogContent sx={{height:'80vh',display:'flex',flexDirection:'column',alignContent:'center'}}>
                        <Box sx={{
                            display:'flex',
                            alignItems:'center',
                            //justifyContent:'space-between',
                            my:'1em',
                            columnGap:'2em',
                        }}>
                            <IconButton onClick={()=>{onCloseRlPilotInfo()}}><ArrowBack/></IconButton>
                            <Typography variant={'h4'} flexGrow={1} textAlign={'left'}>Pilot profile</Typography>
                        </Box>
                        <Box flexGrow={1}>
                            <iframe src={`https://rankingdata.fai.org/SGP_displaypilotdets.php?pilotid=${pilotRlId}`}
                                    height={'100%'}
                                    width={'100%'}
                                    style={{border:'none'}}
                            />
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}

export default PilotEntryList