import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import {ArrowBack} from "@mui/icons-material";
import moment from "moment-timezone";
import {findFlagUrlByIso2Code} from 'country-flags-svg';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {SGP} from "./sgp-results-react";
import {countryArray} from "../util/sgp-util";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {grey} from "@mui/material/colors";
import {isViableRegistrationStatus, registrationStatus} from "../util/standards-util";
import {Alert, Dialog, DialogContent, Link} from "@mui/material";


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '.85em',
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: grey[100],
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const normalize = (v) => {
    return v > 0 ? 1 : v < 0 ? -1 : 0
}

export const mapRegistrationStatusToComparable  = (u)=> {
    switch(u) {
        case registrationStatus.STATUS_OK : return 2;
        case registrationStatus.STATUS_CANCELED : return 1;
        case registrationStatus.STATUS_BANNED : return 0;
        case registrationStatus.STATUS_SUBMITTED : return 2;
        case registrationStatus.STATUS_PRELIMINARY : return 2;
        case registrationStatus.STATUS_PENDING : return 2;
        case registrationStatus.STATUS_REJECTED : return 1;
        case registrationStatus.STATUS_APPROVED : return 2;
        case registrationStatus.STATUS_RESUBMITTED : return 1;
        case registrationStatus.STATUS_ON_PROBATION : return 0;
        case registrationStatus.STATUS_WITHDRAWN : return 1;
    }
}

export const sortEntriesPerSgpRules = (entriesArray, event) => {
    const eventCountry = event.country
    const registrationCutoff = event.registrationCloseDate ? moment(event.registrationCloseDate) : undefined
    let entries = [...entriesArray].sort((a,b) => {
        const aStatus = mapRegistrationStatusToComparable(a.registration.u)
        const bStatus = mapRegistrationStatusToComparable(b.registration.u)

        if (aStatus !== bStatus) return normalize(bStatus - aStatus)

        //See if we have one of the two being a wildcard
        if (
            (
                a.registration.n === 'wildcard' ||
                b.registration.n === 'wildcard'
            ) &&
            a.registration.n !== b.registration.n
        ) {
            if (a.registration.n === 'wildcard') return -1
            else return 1
        }

        //Status equal, so compare rank
        const aRank = Number(a.registration.m)
        const bRank = Number(b.registration.m)
        const aAfterCuttoff = registrationCutoff ? moment(a.registration.r).isAfter(registrationCutoff,'day'): false
        const bAfterCuttoff = registrationCutoff ? moment(b.registration.r).isAfter(registrationCutoff,'day'): false

        if (aAfterCuttoff || bAfterCuttoff) {
            if (aAfterCuttoff && bAfterCuttoff) {
                return normalize(moment(a.registration.r).unix() - moment(b.registration.r).unix())
            }
            else {
                return bAfterCuttoff ? -1 : 1
            }
        }

        if (aRank > 0 && bRank > 0) {
            //we can compare ranks
            return normalize( aRank - bRank )
        }
        else if (aRank>0) {
            return -1
        }
        else if (bRank>0) {
            return 1
        }
        else {
            //no rank info, so compare according to date registered
            return normalize(moment(a.registration.r).unix() - moment(b.registration.r).unix())
        }
    })

    const wildcards = []
    const foreignBerths = []
    const others = []
    const foreignBerthNumber = 5

    entries.map((entry) => {
        if (entry.registration.n === 'wildcard') {
            wildcards.push(entry)
        }
        else if (eventCountry && mapRegistrationStatusToComparable(entry.registration.u) === 2 && entry.pilot.nationality !== eventCountry && foreignBerths.length < foreignBerthNumber && (!registrationCutoff || !moment(entry.registration.r).isAfter(registrationCutoff,'day'))){
            foreignBerths.push(entry)
        }
        else {
            others.push(entry)
        }
    })

    return [].concat(wildcards,foreignBerths,others)
}

const PilotRegistrationList = ({event}) => {

    const eventId = event.id
    const eventCountry = event.country
    const eventEntryNo = 20
    const [mode, setMode] = React.useState('list')
    const [entries, setEntries] = React.useState()
    const [pilotRlId, setPilotRlId] = React.useState()

    const handlePilotClicked = (e,id) => {
        e.stopPropagation()
        setPilotRlId(id)
        setMode('rlPilotInfo')
    }

    const onCloseRlPilotInfo = () => {
        setMode('list')
        setPilotRlId()
    }

    useEffect(() => {
        // fetch data
        const dataFetch = async () => {
            const data = await (
                await fetch(SGP.dataUrl+`/public/get/event/registrations?eventId=${eventId}`)
            ).json();

            const entries = sortEntriesPerSgpRules(
                data.registrations.map((reg)=>{
                    return {
                        registration:reg,
                        pilot:data.pilots.find(p => p.id === reg.p),
                    }
                }),
                event
            )

            setEntries(entries)
        };

        dataFetch();
    }, []);



    const onClosePilotInfo = () => {
        setMode('list')
    }


    return (
        <>
            {entries && mode === 'list' &&
                <TableContainer component={'div'}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>#</StyledTableCell>
                                <StyledTableCell>Pilot</StyledTableCell>
                                <StyledTableCell sx={{textAlign:'center'}}>Nationality</StyledTableCell>
                                <StyledTableCell>Sailplane</StyledTableCell>
                                <StyledTableCell sx={{textAlign: 'right'}}>Ranking</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entries.map((entry,idx) => {
                                const reg= entry.registration
                                const pilot = entry.pilot

                                const sx = idx < eventEntryNo && isViableRegistrationStatus(reg.u) ?
                                    {
                                        '&:last-child td, &:last-child th': {border: 0},
                                        "&:hover": {backgroundColor: "#D3EDF4 !important"},
                                    }
                                    :
                                    {
                                        '&:last-child td, &:last-child th': {border: 0},
                                        '& td, & th': {borderBottom: 'solid 1px #999999'},
                                        "&:hover": {backgroundColor: "#D3EDF4 !important"},
                                        '&:nth-of-type(even)': {
                                            backgroundColor:'#cccccc',
                                        },
                                        '&:nth-of-type(odd)': {
                                            backgroundColor:'#dddddd',
                                        },
                                    }


                                return (
                                    <StyledTableRow
                                        key={reg.i}
                                        sx={sx}
                                    >
                                        <StyledTableCell component="th" scope="row">
                                            {idx < eventEntryNo  && isViableRegistrationStatus(reg.u) ? idx + 1 : '-' }
                                        </StyledTableCell>
                                        <StyledTableCell><Link underline={"none"} onClick={(e) => {handlePilotClicked(e,reg.d)}} sx={{cursor:'pointer'}}>{pilot.firstname} {pilot.lastname}</Link></StyledTableCell>
                                        <StyledTableCell sx={{textAlign:'center'}}><Tooltip title={countryArray.find( c => c.key===pilot.nationality).label} arrow><img src={findFlagUrlByIso2Code(pilot.nationality)} style={{height:'1.2em'}}/></Tooltip></StyledTableCell>
                                        <StyledTableCell>{reg.f}</StyledTableCell>
                                        <StyledTableCell sx={{textAlign: 'right'}}>{reg.m ? reg.m : '-'}</StyledTableCell>
                                    </StyledTableRow>
                                )
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {entries && mode === 'list' && entries.length === 0 &&
                <Alert severity={'info'} sx={{textAlign:'center'}}>There are no pilots registered for this event</Alert>
            }
            { mode === 'rlPilotInfo' &&
                <Dialog onClose={onCloseRlPilotInfo} open={true} fullWidth maxWidth="lg">
                    <DialogContent sx={{height:'80vh',display:'flex',flexDirection:'column',alignContent:'center'}}>
                        <Box sx={{
                            display:'flex',
                            alignItems:'center',
                            //justifyContent:'space-between',
                            my:'1em',
                            columnGap:'2em',
                        }}>
                            <IconButton onClick={()=>{onCloseRlPilotInfo()}}><ArrowBack/></IconButton>
                            <Typography variant={'h4'} flexGrow={1} textAlign={'left'}>Pilot profile</Typography>
                        </Box>
                        <Box flexGrow={1}>
                            <iframe src={`https://rankingdata.fai.org/SGP_displaypilotdets.php?pilotid=${pilotRlId}`}
                                    height={'100%'}
                                    width={'100%'}
                                    style={{border:'none'}}
                            />
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}

export default PilotRegistrationList