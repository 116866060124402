import countries from "i18n-iso-countries";
import enLocale from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(enLocale)

export const countryObj = countries.getNames("en", { select: "official" });
export const countryArray = Object.entries(countryObj).map(([key, value]) => {
    return {label:value, key:key}
});

export const eventStatus= {
    STATUS_ACTIVE: 10,
    STATUS_COMPLETED: 20,
    STATUS_PLANNED_REGISTRATION_CLOSED:25,
    STATUS_PLANNED: 30,
    STATUS_SCHEDULED: 40,
    STATUS_CANCELLED: 90,
}

export const registrationStatus= {
    STATUS_OK : 0x1,
    STATUS_CANCELED : 0x4,
    STATUS_BANNED : 0x5,
    STATUS_SUBMITTED : 0x10,
    STATUS_QUALIFIED: 0x17,
    STATUS_QUALIFIED_RESERVE: 0x18,
    STATUS_PRELIMINARY : 0x19,
    STATUS_PENDING : 0x20,
    STATUS_REJECTED : 0x30,
    STATUS_APPROVED : 0x40,
    STATUS_RESUBMITTED : 0x50,
    STATUS_ON_PROBATION : 0x300,
    STATUS_WITHDRAWN : 0x401,
}

export const isViableRegistrationStatus = (status) =>{
    switch(status) {
        case registrationStatus.STATUS_OK:
        case registrationStatus.STATUS_SUBMITTED:
        case registrationStatus.STATUS_QUALIFIED:
        case registrationStatus.STATUS_QUALIFIED_RESERVE:
        case registrationStatus.STATUS_PRELIMINARY:
        case registrationStatus.STATUS_PENDING:
        case registrationStatus.STATUS_APPROVED:
            return true;
        default: return false;
    }
}

export const competitionRegistrationStatusLabel = {
    0x10: 'Applied',
    0x17: 'Qualified',
    0x18: 'Reserve',
    0x19: 'Pre-registered',
    0x20: 'Pending',
    0x30: 'Rejected',
    0x40: 'Registered',
    0x401: 'Withdrawn',
    0x01: 'OK',
    0x04: 'Canceled',
    0x05: 'Banned',
    0x50: 'Resubmitted',
}


export const competitionRegistrationStatusColor = {
    0x10: 'info',
    0x17: 'success',
    0x18: 'info',
    0x19: 'success',
    0x20: 'warning',
    0x30: 'error',
    0x40: 'success',
    0x401: 'error',
    0x01: 'warning',
    0x04: 'error',
    0x05: 'error',
    0x50: 'warning',
}

export const getRlPilot = (rlid, callback, errorCallback) => {
    const url =`https://www.crosscountry.aero/igcrl/rl/pilot/${rlid}`

    fetch(url, {
        method: "GET",
    })
        .then((response) => response.json())
        .then((data) => {
            callback(data)
        })
        .catch((error) => {
            if (errorCallback) {
                errorCallback(error)
            }
            else {
                console.log('*** ERROR while fetching from ranking list')
                console.log(error)
            }
        })
}