import React, {useEffect} from 'react';
import {useMap} from 'react-leaflet'
import L from "leaflet";

const MapControls = (props) => {
    const map = useMap()

    L.control.scale({position:'bottomright'}).addTo(map)

    const layerControl=new L.Control.Layers();


    layerControl.addBaseLayer(
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                maxZoom: 22
            }),
        'Openstreetmap'
    )
    layerControl.addBaseLayer(
        L.tileLayer('http://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=7031351b401143e099504fd70b980483',
            {
                attribution: 'Map data &copy; <a href="http://www.thunderforest.com">Thunderforest</a>, <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
                maxZoom: 22
            }),
        'Thunderforest Landscape'
    )
    layerControl.addBaseLayer(
        L.tileLayer('http://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=7031351b401143e099504fd70b980483',
            {
                attribution: 'Map data &copy; <a href="http://www.thunderforest.com">Thunderforest</a>, <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
                maxZoom: 22
            }),
        'Thunderforest Outdoors'
    )

    layerControl.addTo(map)
}

export default MapControls




