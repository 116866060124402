import L from "leaflet";

function $id(id) {
    return document.getElementById(id);
}



export const CC={
    g:{},
    ui:{}
};


export const CCI={
    Util: {
        toHmsDurationString: function (millis, zeroHours) {
            if (zeroHours === undefined) zeroHours = '-';
            if (millis == 0) return zeroHours;
            var hr = Math.floor(millis / 3600000);
            var mn = Math.floor((millis - hr * 3600000) / 60000);
            var sec = Math.round((millis - hr * 3600000 - mn * 60000) / 1000);
            if (mn < 10) mn = '0' + mn;
            if (sec < 10) sec = '0' + sec;
            return hr + ":" + mn + ":" + sec;
        },
        toDeltaDurationString: function(millis, zeroHours) {
            if (zeroHours===undefined) zeroHours='-';
            if (millis==0) return zeroHours;
            var hr=Math.floor(millis/3600000);
            var mn=Math.floor((millis-hr*3600000)/60000);
            var sec=Math.round((millis-hr*3600000-mn*60000)/1000);
            if (mn<10) mn='0'+mn;
            if (sec<10) sec='0'+sec;
            return "+"+(hr>0 ? hr+":" :  "")+mn+":"+sec;
        }
    }
};

export const SGP={
    version:'1.0',
    resultsUrl:"https://www.crosscountry.aero",
    dataUrl:"https://data.crosscountry.aero",
    flagsUrl:"https://www.crosscountry.aero/etc/img/flags/",
    etcUrl:"https://www.crosscountry.aero/etc/img/",
    calcPlacingsMap:function(comp) {
        //Use totals for each day but the last one
        //Create a map for each pilot containing placings and number of occurences
        var days=comp.i;
        var pilots=comp.p;
        var totals=comp.t.r;
        var lastDayScores=days[comp.j].r.s;
        var lastDayId=days[comp.j].i;

        var map={};

        for (let i=0;i<days.length;i++){
            let day=days[i];
            if (day.i===lastDayId) break;
            if (day.y!==1) continue;

            let ranks=totals[day.i];
            if (!ranks) continue;

            Object.keys(ranks).map( k => {
                const v = ranks[k]

                let pilotMap=map[k];

                if (!pilotMap) {
                    pilotMap={};
                    map[k]=pilotMap;
                }

                let cnt=pilotMap[v];
                if (cnt===undefined) cnt=0;
                cnt++;
                pilotMap[v]=cnt;
            })
        }

        for (let i=0;i<lastDayScores.length;i++){
            let sc=lastDayScores[i];
            let k=sc.h;
            let v=sc.r;

            let pilotMap=map[k];

            if (!pilotMap) {
                pilotMap={};
                map[k]=pilotMap;
            }

            let cnt=pilotMap[v];
            if (cnt===undefined) cnt=0;
            cnt++;
            pilotMap[v]=cnt;
        }

        return map;
    },
};

export const CCM={};

CCM.task={
    draw:function(task,layer) {
        var t=CCM.task;
        var d=task;

        var g=task.data.g;

        var wpts=[];

        for (var i=0;i<g.length;i++){
            var w=g[i];
            wpts[i]=new L.latLng(w.a, w.o);
        }

        var taskLine=new L.Polyline(wpts,{
            color: '#000680',
            opacity: .9,
            weight: 2
        });

        layer.addLayer(taskLine);

        //TODO: make the following work
        /*
        var declaredOz=[];

        for (var i=0; i<g.length; i++) {
            declaredOz[i]=true;
            var w=g[i];
            switch(w.y) {
                case 'cylinder':
                    layer.addLayer(
                        new CCM.Cylinder(wpts[i] , w.r ? w.r : 500, 5, {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
                case 'line':
                    layer.addLayer(
                        new CCM.Line(wpts[i] , w.r, 5,t.calcOzAzimouth(i,g), {
                            color: '#000680',
                            opacity: .9,
                            weight: 1,
                            fill: true,
                            fillColor: '#ffff00',
                            fillOpacity: 0.5
                        })
                    );
                    break;
            }
        }
         */

        for (var i=0 ; i<wpts.length; i++) {
            var marker = new L.Marker(wpts[i],{
                zIndexOffset: 20-i,
                icon: L.icon({iconUrl:SGP.etcUrl+t._wpIconName(i,wpts.length)+'.png', iconSize:[19,32],iconAnchor:[0,31]})
            });
            layer.addLayer(marker);
        }

    },
    calcOzAzimouth: function(idx,ozs) {
        var oz=ozs[idx];
        if (oz.y=='cylinder') return 0;

        var latlng=new L.LatLng(oz.a,oz.o);
        var next=idx+1<ozs.length ? new L.LatLng(ozs[idx+1].a,ozs[idx+1].o) : undefined;
        var previous=idx==0? undefined : new L.LatLng(ozs[idx-1].a,ozs[idx-1].o);

        if (previous===undefined && next===undefined) return 0;

        var az=0;
        if (previous===undefined) {
            az=CCM.task.calcBearing(latlng,next);
        }
        else if (next===undefined) {
            az=CCM.task.calcBearing(latlng,previous);
        }
        else {
            var az1=CCM.task.calcBearing(latlng,next);
            var az2=CCM.task.calcBearing(latlng,previous);
            az=CCM.task.calcBisector(az1,az2);
        }
        return az;
    },
    calcBearing: function(p1, p2) {
        var dlat=(p2.lat-p1.lat)* Math.PI / 180;
        var dlon=(p2.lng-p1.lng)* Math.PI / 180;
        var rlat1=(p1.lat)* Math.PI / 180;
        var rlat2=(p2.lat)* Math.PI / 180;

        var y=Math.sin(dlon) * Math.cos(rlat2);
        var x=Math.cos(rlat1) * Math.sin(rlat2) - Math.sin(rlat1) * Math.cos(rlat2) * Math.cos(dlon);
        var b= Math.atan2(y, x) * 180 / Math.PI;
        if (b<0.0) b= 360 + b;

        return b;
    },

    calcBisector: function(a1, a2) {
        if (a1>a2) {
            var s=a1;
            a1=a2;
            a2=s;
        }
        var d=a2-a1;
        if (d<=180.0) {
            return (d/2.0+a1) % 360;
        }
        else {
            return ((360-d)/2.0+a2) % 360;
        }
    },
    _wpIconName: function(i,n) {
        if (i==0) return 'wptMarkerS';
        if ((i+1)==n) return 'wptMarkerF';
        return 'wptMarker'+i;
    }
};

CCM.Cylinder = L.Path.extend({
    initialize: function (latlng, radius, pixelRadius,  options) {
        L.setOptions(this.options)

        this._latlng = L.latLng(latlng);
        this._mRadius = radius!==undefined ? radius : this.options.defaultRadius;
        this._pixelRadius=pixelRadius!==undefined ? pixelRadius : this.options.defaultPixelRadius;
        this.ozType='cylinder';
    },

    options: {
        fill: true,
        color: '#000680',
        opacity: .9,
        weight: 1,
        fillColor: '#ffff00',
        fillOpacity: 0.5,
        defaultRadius: 500,
        defaultPixelRadius: 5,
        hideOnMinRadius:false
    },

    setLatLng: function (latlng) {
        this._latlng = L.latLng(latlng);
        return this.redraw();
    },

    setRadius: function (radius) {
        this._mRadius = radius;
        return this.redraw();
    },

    projectLatlngs: function () {
        var lngRadius = this._getLngRadius(),
            latlng2 = new L.LatLng(this._latlng.lat, this._latlng.lng - lngRadius),
            point2 = this._map.latLngToLayerPoint(latlng2);

        this._point = this._map.latLngToLayerPoint(this._latlng);
        this._radius = Math.max(Math.round(this._point.x - point2.x), 1);
    },

    _project: function () {
        //TODO: added this but not sure it should be like this
        this.projectLatlngs()
    },

    _update:function() {
        if (this._map) {
            //TODO: added this fuction but we need to figure out how to render for the new verson of Leaflet
        }
    },

    getBounds: function () {
        var lngRadius = this._getLngRadius(),
            latRadius = (this._mRadius / 40075017) * 360,
            latlng = this._latlng,
            sw = new L.LatLng(latlng.lat - latRadius, latlng.lng - lngRadius),
            ne = new L.LatLng(latlng.lat + latRadius, latlng.lng + lngRadius);

        return new L.LatLngBounds(sw, ne);
    },

    getLatLng: function () {
        return this._latlng;
    },

    getPathString: function () {
        var p = this._point,
            r = this._radius> this._pixelRadius ? this._radius : this._pixelRadius;

        if (this._checkIfEmpty()) {
            return '';
        }

        if (this.options.hideOnMinRadius && this._radius < this._pixelRadius) return '';

        return "M" + p.x + "," + (p.y - r) +
            "A" + r + "," + r + ",0,1,1," +
            (p.x - 0.1) + "," + (p.y - r) + " z";

    },

    isScaledRadius:function() {
        return this._radius ? this._radius >= this._pixelRadius : false;
    },

    getRadius: function () {
        return this._mRadius;
    },
    _getLatRadius: function () {
        return (this._mRadius / 40075017) * 360;
    },

    _getLngRadius: function () {
        return this._getLatRadius() / Math.cos(Math.PI/ 180 * this._latlng.lat);
    },

    _checkIfEmpty: function () {
        if (!this._map) {
            return false;
        }
        return true
    }
});

CCM.cylinder = function (latlng, radius, pixelRadius,  options) {
    return new CCM.Cylinder(latlng, radius, pixelRadius,  options);
};

CCM.Line = CCM.Cylinder.extend({
    initialize: function (latlng, radius, pixelRadius, azimouth, options) {
        CCM.Cylinder.prototype.initialize.call(this, latlng, radius, pixelRadius, options);
        this._azimouth = azimouth;
        this._theta=((this._azimouth+180) % 360)*Math.PI/180;
        this._sinTheta=Math.sin(this._theta);
        this._cosTheta=Math.cos(this._theta);
        this.ozType='line';
    },

    options: {
        defaultRadius: 500,
        defaultPixelRadius: 5
    },

    getPathString: function () {
        var p = this._point,
            r = this._radius> this._pixelRadius ? this._radius : this._pixelRadius,
            p1=new L.Point(this._sinTheta*r/5,-this._cosTheta*r/5),
            p2=new L.Point(this._cosTheta*r,this._sinTheta*r);

        if (this._checkIfEmpty()) {
            return '';
        }

        return "M" + (p.x+p2.x+p1.x) + "," + (p.y+p2.y+p1.y) +
            "L" + (p.x+p2.x) + "," + (p.y+p2.y) +
            "L"+(p.x-p2.x)+" "+(p.y-p2.y)+
            "L"+(p.x-p2.x+p1.x)+" "+(p.y-p2.y+p1.y);

    },

    setAzimouth: function(az) {
        this._azimouth = az;
        this._theta=((this._azimouth+180) % 360)*Math.PI/180;
        this._sinTheta=Math.sin(this._theta);
        this._cosTheta=Math.cos(this._theta);
    }

});

CCM.line = function (latlng, radius, pixelRadius, azimouth, options) {
    return new CCM.Line(latlng, radius, pixelRadius, azimouth, options);
};

SGP.pilots={
    links:{}
};

SGP.seriesLabels={
    0:'VI',
    6:'VI',
    7:'VII',
    8:'VIII',
    9:'IX',
    10:'X',
    11:'XI',
    12:'XII',
    13:'XII',
    14:'XIV',
    15:'XV',
};

SGP.countries={
    AD:'AND',
    AF:'AFG',
    AE:'ARE',
    AG:'ATG',
    AI:'AIA',
    AL:'ALB',
    AM:'ARM',
    AO:'AGO',
    AQ:'ATA',
    AR:'ARG',
    AS:'ASM',
    AT:'AUT',
    AU:'AUS',
    AW:'ABW',
    AX:'ALA',
    AZ:'AZE',
    BA:'BIH',
    BB:'BRB',
    BD:'BGD',
    BE:'BEL',
    BF:'BFA',
    BG:'BGR',
    BH:'BHR',
    BI:'BDI',
    BJ:'BEN',
    BL:'BLM',
    BM:'BMU',
    BN:'BRN',
    BO:'BOL',
    BQ:'BES',
    BR:'BRA',
    BS:'BHS',
    BT:'BTN',
    BV:'BVT',
    BW:'BWA',
    BY:'BLR',
    BZ:'BLZ',
    CA:'CAN',
    CC:'CCK',
    CD:'COD',
    CF:'CAF',
    CG:'COG',
    CH:'CHE',
    CI:'CIV',
    CK:'COK',
    CL:'CHL',
    CM:'CMR',
    CN:'CHN',
    CO:'COL',
    CR:'CRI',
    CU:'CUB',
    CV:'CPV',
    CW:'CUW',
    CX:'CXR',
    CY:'CYP',
    CZ:'CZE',
    DE:'GER',
    DJ:'DJI',
    DK:'DNK',
    DM:'DMA',
    DO:'DOM',
    DZ:'DZA',
    EC:'ECU',
    EE:'EST',
    EG:'EGY',
    EH:'ESH',
    ER:'ERI',
    ES:'ESP',
    ET:'ETH',
    FI:'FIN',
    FJ:'FJI',
    FK:'FLK',
    FM:'FSM',
    FO:'FRO',
    FR:'FRA',
    GA:'GAB',
    GB:'GBR',
    GD:'GRD',
    GE:'GEO',
    GF:'GUF',
    GG:'GGY',
    GH:'GHA',
    GI:'GIB',
    GL:'GRL',
    GM:'GMB',
    GN:'GIN',
    GP:'GLP',
    GQ:'GNQ',
    GR:'GRC',
    GS:'SGS',
    GT:'GTM',
    GU:'GUM',
    GW:'GNB',
    GY:'GUY',
    HK:'HKG',
    HM:'HMD',
    HN:'HND',
    HR:'HRV',
    HT:'HTI',
    HU:'HUN',
    ID:'IDN',
    IE:'IRL',
    IL:'ISR',
    IM:'IMN',
    IN:'IND',
    IO:'IOT',
    IQ:'IRQ',
    IR:'IRN',
    IS:'ISL',
    IT:'ITA',
    JE:'JEY',
    JM:'JAM',
    JO:'JOR',
    JP:'JPN',
    KE:'KEN',
    KG:'KGZ',
    KH:'KHM',
    KI:'KIR',
    KM:'COM',
    KN:'KNA',
    KP:'PRK',
    KR:'KOR',
    KW:'KWT',
    KY:'CYM',
    KZ:'KAZ',
    LA:'LAO',
    LB:'LBN',
    LC:'LCA',
    LI:'LIE',
    LK:'LKA',
    LR:'LBR',
    LS:'LSO',
    LT:'LTU',
    LU:'LUX',
    LV:'LVA',
    LY:'LBY',
    MA:'MAR',
    MC:'MCO',
    MD:'MDA',
    ME:'MNE',
    MF:'MAF',
    MG:'MDG',
    MH:'MHL',
    MK:'MKD',
    ML:'MLI',
    MM:'MMR',
    MN:'MNG',
    MO:'MAC',
    MP:'MNP',
    MQ:'MTQ',
    MR:'MRT',
    MS:'MSR',
    MT:'MLT',
    MU:'MUS',
    MV:'MDV',
    MW:'MWI',
    MX:'MEX',
    MY:'MYS',
    MZ:'MOZ',
    NA:'NAM',
    NC:'NCL',
    NE:'NER',
    NF:'NFK',
    NG:'NGA',
    NI:'NIC',
    NL:'NLD',
    NO:'NOR',
    NP:'NPL',
    NR:'NRU',
    NU:'NIU',
    NZ:'NZL',
    OM:'OMN',
    PA:'PAN',
    PE:'PER',
    PF:'PYF',
    PG:'PNG',
    PH:'PHL',
    PK:'PAK',
    PL:'POL',
    PM:'SPM',
    PN:'PCN',
    PR:'PRI',
    PS:'PSE',
    PT:'PRT',
    PW:'PLW',
    PY:'PRY',
    QA:'QAT',
    RE:'REU',
    RO:'ROU',
    RS:'SRB',
    RU:'RUS',
    RW:'RWA',
    SA:'SAU',
    SB:'SLB',
    SC:'SYC',
    SD:'SDN',
    SE:'SWE',
    SG:'SGP',
    SH:'SHN',
    SI:'SVN',
    SJ:'SJM',
    SK:'SVK',
    SL:'SLE',
    SM:'SMR',
    SN:'SEN',
    SO:'SOM',
    SR:'SUR',
    SS:'SSD',
    ST:'STP',
    SV:'SLV',
    SX:'SXM',
    SY:'SYR',
    SZ:'SWZ',
    TC:'TCA',
    TD:'TCD',
    TF:'ATF',
    TG:'TGO',
    TH:'THA',
    TJ:'TJK',
    TK:'TKL',
    TL:'TLS',
    TM:'TKM',
    TN:'TUN',
    TO:'TON',
    TR:'TUR',
    TT:'TTO',
    TV:'TUV',
    TW:'TWN',
    TZ:'TZA',
    UA:'UKR',
    UG:'UGA',
    UM:'UMI',
    US:'USA',
    UY:'URY',
    UZ:'UZB',
    VA:'VAT',
    VC:'VCT',
    VE:'VEN',
    VG:'VGB',
    VI:'VIR',
    VN:'VNM',
    VU:'VUT',
    WF:'WLF',
    WS:'WSM',
    YE:'YEM',
    YT:'MYT',
    ZA:'ZAF',
    ZM:'ZMB',
    ZW:'ZWE'
};

SGP.getChampionshipTitle = (comp) => {
    return `FAI Sailplane Grand Prix - Series ${romanize(comp.c.s)}`
}

const romanize = (num) => {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
            "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
            "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}


