import './App.css';
import React from 'react';
import EventResults from "./Competition/EventResults";
import {Container} from "@mui/material";
import MenuBar from "./Components/MenuBar";
import Pilots from "./Competition/Pilots";

function App({ editionId, noEmbed, mode='results' }) {

  if (noEmbed) return (
      <>
          <MenuBar/>
          <Container>
              {mode === 'results' &&
                <EventResults editionId={editionId}/>
              }
              {mode === 'pilots' &&
                  <Pilots editionId={editionId}/>
              }
          </Container>
      </>
    )
  else return (
      <>
          {mode === 'results' &&
              <EventResults editionId={editionId}/>
          }
          {mode === 'pilots' &&
              <Pilots editionId={editionId}/>
          }
      </>
  )
}

export default App;
